import React from 'react';
import './App.css';

//TODO: gg analytics
//TODO: Cursor
//TODO: Sound
//TODO: multiple faces
//TODO: giclette dynamic
//TODO: Img Downloader
//TODO: Social Share
//TODO: Game mode?

// Inspi for semen: https://codepen.io/digitalcraft/pen/MwoyZd/

import ImgUploader from './imguploader';

function App() {
  return (
    <div className="App">
      <div className="App-inner">
        <header className="App-header">
          <h1>La faciale</h1>
        </header>
        <ImgUploader />
      </div>
    </div>
  );
}

export default App;
