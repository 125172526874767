import React, { Component } from 'react';

import * as faceapi from 'face-api.js';


// FACE RECO TUTO : https://itnext.io/face-api-js-javascript-api-for-face-recognition-in-the-browser-with-tensorflow-js-bcc2a6c4cf07

// TUTO to send img somewhere
// https://www.google.com/search?q=react+image+uploader&oq=react+image+uploader&aqs=chrome.0.69i59j0l7.7871j0j4

//Loading the models
const MODEL_URL = '/face-api-models';
const getOverlayValues = landmarks => {
  const nose = landmarks.getNose()
  const jawline = landmarks.getJawOutline()

  const jawLeft = jawline[0]
  const jawRight = jawline.splice(-1)[0]
  const adjacent = jawRight.x - jawLeft.x
  const opposite = jawRight.y - jawLeft.y
  const jawLength = Math.sqrt(Math.pow(adjacent, 2) + Math.pow(opposite, 2))

  // Both of these work. The chat believes atan2 is better.
  // I don't know why. (It doesn’t break if we divide by zero.)
  // const angle = Math.round(Math.tan(opposite / adjacent) * 100)
  const angle = Math.atan2(opposite, adjacent) * (180 / Math.PI)
  const width = jawLength * 2.2

  return {
    width,
    angle,
    leftOffset: jawLeft.x - width * 0.27,
    topOffset: nose[0].y - width * 0.47,
  }
}

const getRandomMask = masks => {
  const index = Math.floor(masks.length * Math.random())

  return masks[index]
}

const masks = [
      "./imgs/semen-1.png",
      "./imgs/semen-2.png",
      "./imgs/semen-3.png",
      "./imgs/semen-4.png",
      "./imgs/semen-6.png",
    ]


export default class ImgUploader extends Component {

	constructor(props) {
    super(props);
    this.state = {
    	ejacEnable: false,
			selectedFile: null, 
			scale: 1,
			overlayValues: {}
    }

  }

	async componentDidMount() {
		this.setState({selectedFile: null, semenLayers: []});
	  await Promise.all([
	    faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
	    faceapi.nets.faceLandmark68TinyNet.loadFromUri(MODEL_URL),
	  ]).catch(error => {
	    console.error(error)
	  })
	  this.semenLayers = document.getElementById('semen-layers');
	}

	

	fileSelectedHandler = event => {
		let _self = this;
		this.setState({selectedFile: URL.createObjectURL(event.target.files[0])});
		this.semenLayers.innerHTML = "";
		const input = document.getElementById('uploaded-pic');

		// Call function with a timer to let the img load
		setTimeout(function(){_self.faceDetect(input)},1000);
	}

	async faceDetect(imgToAnalyze) {
		this.setState({scale : imgToAnalyze.width / imgToAnalyze.naturalWidth});
		const detection = await faceapi
      .detectSingleFace(imgToAnalyze, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks(true)

    if (!detection) {
    	this.setState({helperMessage: 'No face to cum on :/', ejacEnable: false})
      return
    }

    this.setState({overlayValues : getOverlayValues(detection.landmarks), ejacEnable: true, helperMessage:''});
		
	} 

	fileUploadHandler = () => {
		//console.log('test');
	}

	ejaculate = () => {
		if (this.state.ejacEnable){
	    const overlay = document.createElement("img")
	    let randomizeWidth = Math.random();
	    let randomizeX = Math.floor((Math.random() * 20) - 5);
	    let randomizeY = Math.floor((Math.random() * 20) - 5);
	    let randomizeRotation = Math.floor(Math.random() * 10);
	    overlay.src = getRandomMask(masks)
	    overlay.alt = "mask overlay."
	    overlay.style.cssText = `
	      position: absolute;
	      left: ${this.state.overlayValues.leftOffset * this.state.scale + randomizeX}px;
	      top: ${this.state.overlayValues.topOffset * this.state.scale + randomizeY}px;
	      width: ${this.state.overlayValues.width * this.state.scale * randomizeWidth}px;
	      transform: rotate(${this.state.overlayValues.angle}deg);
	    `
	    this.semenLayers.appendChild(overlay)
	    console.log(this.state.overlayValues.angle + '   '  + randomizeRotation);
	  } else {
	  	this.setState({helperMessage: 'No face to cum on :/'})
	  }
	}

	render() {

		return(
			<div className="uploader">
				<div className={`img-wrapper ${this.state.selectedFile ? 'img-uploaded' : 'img-hidden'}`} onClick={this.ejaculate}>
					<p className="helper-message">{this.state.helperMessage}</p>
	        <div id="semen-layers"></div>
					<img id="uploaded-pic" className="uploaded-pic" alt={`uploaded face`} src={this.state.selectedFile}/>
				</div>
				<input className="upload" id="file "type="file" onChange={this.fileSelectedHandler} />
			</div>
			)
	}
}